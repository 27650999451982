<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <!-- Payouts List -->
        <div class="row clearfix">
            <b-modal id="bank_modal" :title="modalTitle" hide-footer @shown="resetForm">
                <div class="modal-area">
                    <ValidationObserver v-slot="{ passes }">
                        <ul class="list-unstyled mb-0 payment-single-transfer">
                            <form id="funding_option" @submit.prevent="passes(addBank)">
                                <li v-if="bankId==''">
                                    <ValidationProvider name="accountName" rules="required" v-slot="{ errors}">
                                        <div class="form-group form-select single-multiselect" :class="{'errors': errors[0],'success':bankData.country && bankData.country.length != 0  && !errors[0]}">
                                            <span class="float-label">
                                                <label for="functions">Select Country*</label>
                                                <multiselect
                                                    placeholder="Select Country"
                                                    v-model="bankData.country"  
                                                    :show-labels="false" 
                                                    :options="countrys_options" 
                                                    label="name"
                                                    :searchable="true">
                                                </multiselect> 
                                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                <i class="fa fa-check" v-if="bankData.country && bankData.country.length != 0 && !errors[0]"></i>
                                            </span>
                                        </div>
                                    </ValidationProvider>
                                </li>
                                <li>  
                                    <ValidationProvider name="name" rules="required" v-slot="{ errors}">   
                                        <div class="form-group" :class="{'errors': errors.length && !bankData.name || errors[0],'success':bankData.name && !errors[0]} ">
                                            <span class="float-label">
                                                <input type="text" v-model="bankData.name" class="form-control" name="name " id="name " placeholder="Name*">
                                                <label for="category_name " class="control-label">Bank Name*</label>
                                                <div class="line"></div>
                                                <i class="fa fa-times" v-if="errors.length && !bankData.name || errors[0] "></i>
                                                <i class="fa fa-check" v-if="bankData.name && !errors[0]"></i>
                                            </span>
                                        </div>
                                    </ValidationProvider>
                                </li>
                                <li v-if="bankId==''">  
                                    <ValidationProvider name="bankcode" rules="required" v-slot="{ errors}">   
                                        <div class="form-group" :class="{'errors': errors.length && !bankData.bankcode || errors[0],'success':bankData.bankcode && !errors[0]} ">
                                            <span class="float-label">
                                                <input type="text" v-model="bankData.bankcode" class="form-control" name="bankcode " id="bankcode" placeholder="Bank Code*">
                                                <label for="category_name " class="control-label">Bank Code*</label>
                                                <div class="line"></div>
                                                <i class="fa fa-times" v-if="errors.length && !bankData.bankcode || errors[0] "></i>
                                                <i class="fa fa-check" v-if="bankData.bankcode && !errors[0]"></i>
                                            </span>
                                        </div>
                                    </ValidationProvider>
                                </li>
                                <li>
                                    <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                        <b-button v-if="bankId==''" type="submit" variant="primary btn-mw" size="lg">Add Bank </b-button>
                                        <b-button v-if="bankId!=''" type="submit" variant="primary btn-mw" size="lg">Update Bank </b-button>
                                        <b-button @click="$bvModal.hide('bank_modal')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                                    </div>
                                </li>
                            
                            </form>
                        </ul>
                    </ValidationObserver>
                </div>
            </b-modal>  
            <div class="col-lg-12">
                <div class="card card-top-line">
                    <!-- Header -->
                    <div class="header d-flex justify-content-between align-items-center">
                        <h2>Bank List</h2>
                    </div>
                    <div class="body pt-0">
                        <div class="chart-top-action d-md-flex justify-content-end py-3">
                            <!-- SearchBox Filters -->
                            <div class="mr-0 mr-md-3 action-top-search">
                                <div class="input-group">
                                    <input type="text" class="form-control" v-model="searchText" placeholder="Search" aria-label="Search" aria-describedby="search-text"  v-on:keyup.enter="refreshBankTable">
                                    <div class="input-group-append" @click="refreshBankTable()">
                                        <span class="input-group-text" id="search-mail"><i class="icon-magnifier"></i></span>
                                    </div>
                                    <div class="input-group-append" @click="clearSearch()" v-if="this.searchText">
                                        <span class="input-group-text" id="search-mail"><i class="icon-close"></i></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Toggle Filter Button -->
                            <div class="fliter-main mr-auto mt-3 mt-md-0">
                                <div class="country-dropdown curancy-dropdown mb-0">
                                    <div class="form-group form-select multiselect-grp mb-0">
                                        <span class="float-label">
                                            <label for="roles">Select Country</label>
                                            <multiselect
                                                placeholder="Select Country"
                                                v-model="country" 
                                                :show-labels="false" 
                                                :options="countrys_options" 
                                                :searchable="true"
                                                label="name"
                                                @input="refreshBankTable">
                                            </multiselect>  
                                        </span>
                                    </div>
                                </div>
                            </div>                            

                            <div class="payment-dropdown d-flex flex-lg-row-reverse flex-column ml-lg-3 ml-0 mt-3 mt-lg-0">
                                <b-button size="lg" variant="primary btn-mw" @click="createBank()">Add Bank <i class="fa fa-plus-square"></i></b-button>
                                <!-- <button class="btn btn-outline-primary btn-lg mr-3">Download CSV <i class="fa fa-download ml-1"></i></button>
                                <button class="btn btn-outline-primary btn-lg mr-3">Upload <i class="fa fa-upload ml-1"></i></button>                                 -->
                            </div>
                        </div>
                        <b-table 
                            responsive
                            ref="bankTable"
                            name="bank"
                            outlined
                            table-class="mb-0 table-custom"
                            head-variant="light"
                            :items="getBankData" 
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection" :emptyText="'No data found.'" show-empty>
                            <template #head()="{label,  field: { key }}">
                                {{ label }}
                                <!-- Custom icons -->
                                <template>
                                    <i v-if="sortBy !== key" class=""></i>
                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                </template>  
                            </template>
                            <template #cell(uuid)="data">
                                <b-button 
                                    id="button1" 
                                    variant="outline-primary" 
                                    size="sm" 
                                    v-b-tooltip.hover
                                    :data-original-title="data.value.id"
                                    :title="data.value.id" 
                                    @click.prevent="copyTooltip($event)">
                                    <i class="fa fa-2x fa-barcode"></i>
                                </b-button>
                            </template>
                            <template #cell(provider)="data">
                                {{ data.value.name.substring(0, 19) }}<span v-if="data.value.name.length > 19">...</span>
                            </template>
                            <template #cell(actions)="row">
                                <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                    <b-dropdown-item @click="showEdit(row)">Edit</b-dropdown-item>
                                    <b-dropdown-item @click="deleteBank(row.item.id)">Delete</b-dropdown-item>
                                </b-dropdown>
                            </template>
                            <template v-slot:cell(show_details)="item">
                                <a class="btn btn-outline-primary"  href="javascript:void(0);" @click="showProvider(item)">
                                    <i class="fa fa-eye"></i>
                                </a>
                            </template>

                            <!-- <template v-slot:cell(show_details)="{ detailsShowing, item }">
                                <a class="btn" :class="[detailsShowing  ? 'btn-primary text-white' : 'btn-outline-primary']" href="javascript:void(0);" @click="toggleDetails(item); showProvider(item)">
                                    <i class="fa fa-angle-down" v-if="!detailsShowing"></i>
                                    <i class="fa fa-angle-up" v-if="detailsShowing"></i>
                                </a>
                            </template> -->
                            <!-- <template v-slot:row-details>
                                <b-card class="scroll-table">
                                    <div class="chart-top-action d-md-flex justify-content-end py-3">
                                        <div class="payment-dropdown d-flex flex-lg-row-reverse flex-column ml-lg-3 ml-0 mt-lg-0">
                                            <b-button size="lg" variant="primary btn-mw" @click="createProvider()">Add Provider <i class="fa fa-plus-square"></i></b-button>
                                        </div>

                                        <b-modal id="provider_modal" :title="modalTitle" hide-footer @shown="resetProviderForm">
                                            <div class="modal-area">
                                                <ValidationObserver v-slot="{ passes }">
                                                    <ul class="list-unstyled mb-0 payment-single-transfer">
                                                        <form id="funding_option" @submit.prevent="passes(addProvider)">
                                                            <li>
                                                                <ValidationProvider name="provider" rules="required" v-slot="{ errors}">
                                                                    <div class="form-group form-select single-multiselect" :class="{'errors': errors[0],'success':providerData.provider && providerData.provider.length != 0  && !errors[0]}">
                                                                        <span class="float-label">
                                                                            <label for="functions">Select Provider*</label>
                                                                            <multiselect
                                                                                placeholder="Select provider"
                                                                                v-model="providerData.provider"  
                                                                                :show-labels="false" 
                                                                                :options="masterProviderItems" 
                                                                                label="name"
                                                                                :searchable="true">
                                                                            </multiselect> 
                                                                            <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                            <i class="fa fa-check" v-if="providerData.provider && providerData.provider.length != 0 && !errors[0]"></i>
                                                                        </span>
                                                                    </div>
                                                                </ValidationProvider>
                                                            </li>
                                                            <li>
                                                                <ValidationProvider name="currency" rules="required" v-slot="{ errors}">
                                                                    <div class="form-group form-select single-multiselect"
                                                                        :class="{'errors': errors[0],'success':providerData.currency && providerData.currency.length != 0  && !errors[0]}">
                                                                        <span class="float-label">
                                                                            <label for="functions">Select Currency*</label>
                                                                            <multiselect placeholder="Select Currency" v-model="providerData.currency" :show-labels="false"
                                                                                :options="currencyItems" label="name" :searchable="true">
                                                                            </multiselect>
                                                                            <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                            <i class="fa fa-check"
                                                                                v-if="providerData.currency && providerData.currency.length != 0 && !errors[0]"></i>
                                                                        </span>
                                                                    </div>
                                                                </ValidationProvider>
                                                            </li>
                                                            <li>  
                                                                <ValidationProvider name="assignedname" rules="required" v-slot="{ errors}">   
                                                                    <div class="form-group" :class="{'errors': errors.length && !providerData.assignedname || errors[0],'success':providerData.assignedname && !errors[0]} ">
                                                                        <span class="float-label">
                                                                            <input type="text" v-model="providerData.assignedname" class="form-control" name="assignedname " id="assignedname" placeholder="Bank Assigned Name*">
                                                                            <label for="category_name " class="control-label">Bank Assigned Name*</label>
                                                                            <div class="line"></div>
                                                                            <i class="fa fa-times" v-if="errors.length && !providerData.assignedname || errors[0] "></i>
                                                                            <i class="fa fa-check" v-if="providerData.assignedname && !errors[0]"></i>
                                                                        </span>
                                                                    </div>
                                                                </ValidationProvider>
                                                            </li>
                                                            <li>  
                                                                <ValidationProvider name="assignedcode" rules="required" v-slot="{ errors}">   
                                                                    <div class="form-group" :class="{'errors': errors.length && !providerData.assignedcode || errors[0],'success':providerData.assignedcode && !errors[0]} ">
                                                                        <span class="float-label">
                                                                            <input type="text" v-model="providerData.assignedcode" class="form-control" name="assignedcode" id="assignedcode" placeholder="Bank Assigned Code*">
                                                                            <label for="category_name " class="control-label">Bank Assigned Code*</label>
                                                                            <div class="line"></div>
                                                                            <i class="fa fa-times" v-if="errors.length && !providerData.assignedcode || errors[0] "></i>
                                                                            <i class="fa fa-check" v-if="providerData.assignedcode && !errors[0]"></i>
                                                                        </span>
                                                                    </div>
                                                                </ValidationProvider>
                                                            </li>
                                                            <li>
                                                                <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                                                    <b-button v-if="editProviderId==''" type="submit" variant="primary btn-mw" size="lg">Add Provider </b-button>
                                                                    <b-button v-if="editProviderId!=''" type="submit" variant="primary btn-mw" size="lg">Update Provider </b-button>
                                                                    <b-button @click="$bvModal.hide('provider_modal')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                                                                </div>
                                                            </li>
                                                        
                                                        </form>
                                                    </ul>
                                                </ValidationObserver>
                                            </div>
                                        </b-modal> 
                                    </div>
                                    <b-table 
                                        ref="providerTable"
                                        :id="bankcode"
                                        responsive
                                        hover 
                                        outlined
                                        table-class="js-basic-example table-custom mb-0"
                                        head-variant="light"
                                        :items="providerItems" 
                                        :fields="providerFields"
                                        :sort-direction="sortDirection" :emptyText="'No data found.'" show-empty>
                                        <template #cell(actions)="row">
                                            <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                                <b-dropdown-item @click="showProviderEdit(row)">Edit</b-dropdown-item>
                                                <b-dropdown-item @click="deleteProvider(row.item.id)">Delete</b-dropdown-item>
                                            </b-dropdown>
                                        </template>
                                    </b-table>
                                </b-card>
                            </template> -->
                        </b-table>
                        <!-- Table Pagination -->
                        <div class="py-3 align-items-center justify-content-between table-pagination" :class="totalRows > 0?' d-flex':'d-none'">
                            <div class="per-page">
                                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="number-page">
                                <b-pagination 
                                    v-model="currentPage" 
                                    :total-rows="totalRows" 
                                    :per-page="perPage"
                                    hide-goto-end-buttons 
                                    hide-ellipsis 
                                    prev-text="Previous page" 
                                    next-text="Next page" 
                                    align="right">
                                </b-pagination>
                            </div>
                            <div class="go-page">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Go To Page</span>
                                    </div>
                                    <input type="text" v-model="currentPageJump" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
    </div>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
import '@/plugins/sweetalert2'
const LoaderComponent = require("@/components/admin/loader/LoaderComponent.vue").default;
import shared from "@/shared.js";
export default {
    name:'BankComponent',
    components: {
        BreadCrumb : () => import('@/components/BreadCrumb.vue'),
        ValidationObserver,
        ValidationProvider,
        Multiselect: () => import('vue-multiselect'),
        "main-loader": LoaderComponent,
    },
    methods: {
        //go to pagination
        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = this.currentPageJump = e.target.value
                } else {
                   this.currentPage = this.currentPageJump = paginationMenu;
                }
            } else {
                this.currentPage = this.currentPageJump = 1;
            }
        },

        copyTooltip: function(e){
            var tooltipText = e.currentTarget.getAttribute('data-original-title');
            const inputCopyText = document.createElement('input')
            inputCopyText.value = tooltipText
            document.body.appendChild(inputCopyText)
            inputCopyText.select()
            document.execCommand('copy')
            document.body.removeChild(inputCopyText)
        },

        async getBankData(ctx) {
            try {
                this.$refs.loader.show();
                let page  = '';
                let size = '';
                // let sortby = '';
                // let sort = false;
                if(ctx.currentPage != undefined) {
                    page =  ctx.currentPage;
                } else {
                    page = 1;
                }
                if(ctx.perPage != undefined) {
                    size = ctx.perPage;
                } else {
                    size = 10;
                }
                // if(ctx.sortBy == '') {
                //     sortby = 'createdat'
                // } else {
                //     sortby = ctx.sortBy;
                // }
                // if(ctx.sortDesc == false) {
                //     sort = 'asc'
                // } else {
                //     sort = 'desc';
                // }
                this.currentPageJump = page;
                this.items = [];
                let paramData = {
                    limit : size,
                    offset : page,
                }
                if(this.searchText){
                    paramData.name = this.searchText
                }
                if(this.country){
                    paramData.countryid = this.country.id
                }
                const response = await this.$http.get(this.$standapp + "customer/beneficiary/banklists/",{
                    params: paramData,
                    headers: {
                        Authorization: 'Basic YmRkMTc5NTY5MmQ1NTBiMWIwY2M0YmUzZmJkN2MyMTY6ZTczYTdhNDhjNDYzNDM5Nzg1MTUyNTI4MWE4NzdkMWY=',//"Bearer " + sessionStorage.getItem("jwtToken"),
                    },
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                
                if (response.data.code == "0086-000") {
                    response.data.data.map((item) => {
                        let providerset = '';
                        if(item.providers){
                            // const abc = [{assigned_name:'Ghipss'},{assigned_name:'Terrapay'}];
                            providerset = item.providers.map(provider => provider.provider_data.name).join(', ');
                        }else{
                            providerset = '-';
                        }

                        let lists = {
                            id: item.bankid,
                            uuid: {id:item.bankid},
                            name: item.name,
                            code: item.bank_code,
                            countryid: item.country.countryid,
                            countryname: item.country.countryname,
                            provider: {name : providerset},
                            // status: {id:'status_1',isChecked:true},
                            actions:'',
                        };
                        this.items.push(lists);
                    });
                    this.totalRows = response.data.totalrecords;
                    this.$refs.loader.hide();
                    return this.items;  
                }

            } catch (error) {
                console.log(error);
                this.$refs.loader.hide();
            }
        },
        refreshBankTable() {
            this.$refs.bankTable.refresh();
        },
        clearSearch(){
            this.searchText = '';
            this.$refs.bankTable.refresh();
        },
        async allCountry(){
            try {
                const response = await this.$http.get(this.$baseurl+"countries");
                //JSON responses are automatically parsed.
                response.data.map((item) => {
                    let countryData = {
                        id:item.countryid, 
                        isocode: item.isocode, 
                        code: item.mobilecountrycode, 
                        name: item.countryname, 
                        flagClass:"flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-xl fi-round",
                    }
                    this.countrys_options.push(countryData);
                })
            } catch (error) {
                console.log(error);
                // shared.toastrError(error);
            }
        },
        
        resetForm(){
            this.btnDesabled = false;
            if(this.bankId== '') {
                this.bankData.name = '';
                this.modalTitle = 'Add Bank';
                
            } else {
                this.modalTitle = 'Edit Bank';

            }
        },

        createBank(){
            this.bankId = '';
            this.$bvModal.show('bank_modal');
        },

        showEdit(element) {
            this.bankData.name = element.item.name;
            this.bankId = element.item.id;
            this.$bvModal.show('bank_modal');
        },

        addBank(){
            if(this.bankId!='') {
                this.updateBank();   
            } else {
                let bankData = {
                    name: this.bankData.name,
                    countryid: this.bankData.country.id,
                    bank_code: this.bankData.bankcode,
                    swiftcode: this.bankData.bankcode
                };
                // console.log(bankData);
                
                this.$http.post(this.$standapp+"customer/beneficiary/banklists/", bankData,{
                    headers: {
                        'Authorization': 'Basic ZDg3Zjk1ODlmNDdhNTk1YmFiOWUzNDg5ODAwMTg4NGQ6MGU3MzUzZGQ0ZmUyNGFlN2EzNDgzZmZkNWIzNTAyMzc='
                    },
                }).then((res) => {
                    // console.log(res);
                    if(res.data.code == '0086-000') {
                        shared.toastrSuccess('Bank Successfully Added');
                        this.refreshBankTable();
                        this.$bvModal.hide('bank_modal');
                        this.apiErrorMsg = '';
                        this.apiErrorCode = '';
                    }
                }).catch((error) => {
                    this.apiErrorCode = error.response.data.code;
                    this.apiErrorMsg = error.response.data.message;
                });
            }
        },

        updateBank() {
            this.$refs.loader.show();
            let bankData = {
                pk: this.bankId,
                name: this.bankData.name
            };
            this.$http.put(this.$standapp + "customer/beneficiary/banklists/", bankData,{
                headers: {
                    'Authorization': 'Basic ZDg3Zjk1ODlmNDdhNTk1YmFiOWUzNDg5ODAwMTg4NGQ6MGU3MzUzZGQ0ZmUyNGFlN2EzNDgzZmZkNWIzNTAyMzc=' //`Basic ${token}`
                },
            }).then((res) => {
                console.log(res);
                // if(res.data.code == '0100-300') {
                //     //this.$swal('Success!', res.data.message, 'success', 'Close');
                    shared.toastrSuccess('Bank Name Successfully updated');
                    this.refreshBankTable();
                    this.$bvModal.hide('bank_modal');
                    this.$refs.loader.hide();
                // }
            }).catch((error) => {
                this.apiErrorCode = error.response.data.code;
                this.apiErrorMsg = error.response.data.message;
                this.$refs.loader.hide();
            });             
        },

        deleteBank(bankID) {
            shared.deleteConfirm().then((result) => {
                if (result.isConfirmed) {
                    this.$refs.loader.show();
                    let bankData = {
                        pk: bankID
                    };
                    this.$http.delete(this.$standapp + "customer/beneficiary/banklists/",{
                        headers: {
                            'Authorization': 'Basic ZDg3Zjk1ODlmNDdhNTk1YmFiOWUzNDg5ODAwMTg4NGQ6MGU3MzUzZGQ0ZmUyNGFlN2EzNDgzZmZkNWIzNTAyMzc=' //`Basic ${token}`
                        },
                        data : bankData
                    }).then((res) => {
                        if(res.data.code == '0086') {
                            shared.toastrSuccess('Bank Successfully deleted');
                            this.refreshBankTable();
                            this.$refs.loader.hide();
                        } else {
                            this.refreshBankTable();
                            this.$refs.loader.hide();
                            shared.toastrError(res.data.message);
                        }
                    }).catch((error) => {
                        this.apiErrorCode = error.response.data.code;
                        this.apiErrorMessage = error.response.data.message;
                    });
                }
            })
        }, 

        showProvider(item){
            // console.log(item.item);
            const bankDetail = item.item;
            // this.bankID = item.id;
            // this.countryID = item.countryid;
            // this.bankcode = item.code;
            sessionStorage.setItem('bankId',bankDetail.id);
            sessionStorage.setItem('countryID',bankDetail.countryid);
            sessionStorage.setItem('bankcode',bankDetail.code);
            let route = this.$router.resolve({path: '/list/provider'});
            window.open(route.href, '_blank');

            // this.$router.push({path:'/list/bank/provider'});
            // this.getProvider();
        },
        
        // async getProvider(){
        //     try {
        //         this.$refs.loader.show();

        //         this.providerItems = [];
        //         let paramData = {
        //             limit : 100,
        //             offset : 1,
        //             bank_code : this.bankcode//bankcode
        //         };
        //         const response = await this.$http.get(this.$standapp + "customer/beneficiary/banklists/",{
        //             params: paramData,
        //             headers: {
        //                 Authorization: 'Basic YmRkMTc5NTY5MmQ1NTBiMWIwY2M0YmUzZmJkN2MyMTY6ZTczYTdhNDhjNDYzNDM5Nzg1MTUyNTI4MWE4NzdkMWY=',//"Bearer " + sessionStorage.getItem("jwtToken"),
        //             },
        //         });
        //         // JSON responses are automatically parsed.
        //         console.log(response);
        //         if(response.data.data[0].providers){
        //             response.data.data[0].providers.map((item) => {
        //                 let items = {
        //                     id:item.id,
        //                     providerid:item.provider_data.id,
        //                     name:item.provider_data.name,
        //                     assignname: item.assigned_name,
        //                     assigncode:item.assignedcode,
        //                     currency:item.currency
        //                 };
        //                 this.providerItems.push(items);
        //             }); 
        //         }
        //         this.$refs.loader.hide();
        //         return this.providerItems;               
        //     } catch (error) {
        //         console.log(error);
        //         this.$refs.loader.hide();
        //     }
        // },

        // toggleDetails(row) {
        //     if(row._showDetails){
        //         this.$set(row, '_showDetails', false)
        //     }else{
        //         this.items.forEach(item => {
        //             this.$set(item, '_showDetails', false)
        //         })

        //         this.$nextTick(() => {
        //             this.$set(row, '_showDetails', true)
        //         })
        //     }
        // },

        // createProvider(){
        //     this.currencyList(this.countryID);
        //     this.masterProviderList();
        //     this.$bvModal.show('provider_modal');
        // },

        // async currencyList(countryid){
        //     try {
        //         let paramData = {
        //             page : 1,
        //             size : 100,
        //             sort : 'asc',
        //             sortby : 'createdat'
        //             // countryid : countryid
        //         };
        //         const response = await this.$http.get(this.$baseurl + "currencies/supported/"+countryid,{
        //             params: paramData,
        //             headers: {
        //                 Authorization: 'Bearer '+ sessionStorage.getItem("jwtToken"),
        //             },
        //         });
        //         console.log(response);
        //         //JSON responses are automatically parsed.
        //         response.data.details.map((item) => {
        //             let currencydata = {
        //                 // id:item.countryid, 
        //                 name: item.currencycode //'INR'
        //             }
        //             this.currencyItems.push(currencydata);
        //         })
        //     } catch (error) {
        //         console.log(error);
        //         // shared.toastrError(error);
        //     }
        // },

        // async masterProviderList (){
        //     try {
        //         this.$refs.loader.show();
        //         this.masterProviderItems = [];
        //         const response = await this.$http.get(this.$apiV2 + "payment/provider-list/",{
        //             headers: {
        //                 Authorization: 'Basic ZDg3Zjk1ODlmNDdhNTk1YmFiOWUzNDg5ODAwMTg4NGQ6MGU3MzUzZGQ0ZmUyNGFlN2EzNDgzZmZkNWIzNTAyMzc=',
        //             },
        //         });
        //         // JSON responses are automatically parsed.
        //         // console.log(response);
        //         response.data.data.map((item) => {
        //             let items = {
        //                 name:item.name,
        //                 id:item.providerid
        //             };
        //             this.masterProviderItems.push(items);
        //         }); 
        //         this.$refs.loader.hide();
        //         return this.masterProviderItems;               
        //     } catch (error) {
        //         console.log(error);
        //         this.$refs.loader.hide();
        //     }
        // },

        // resetProviderForm(){
        //     this.btnDesabled = false;
        //     if(this.editProviderId == '') {
        //         this.bankData.name = '';
        //         this.modalTitle = 'Add Provider';
                
        //     } else {
        //         this.modalTitle = 'Edit Provider';

        //     }
        // },  
        
        // refreshProviderTable() {
        //     console.log('refresh provider');
        //     // const trst = 'providerTable'+this.bankcode;
        //     // console.log(trst);
        //     // this.$refs.trst.refresh();
        //     this.$root.$emit('bv::refresh::table', this.bankcode)
        //     console.log('End refresh');
        // },

        // addProvider(){
        //     if(this.editProviderId!='') {
        //         this.updateProvider();   
        //     } else {
        //         this.$refs.loader.show();
        //         let providerData = {
        //             providerid: this.providerData.provider.id,
        //             bankid: this.bankID,
        //             assignedcode: this.providerData.assignedcode,
        //             assigned_name: this.providerData.assignedname,
        //             countryid: this.countryID,
        //             currency: this.providerData.currency.name
        //         };
        //         // console.log(providerData);
                
        //         this.$http.post(this.$standapp+"customer/bank/providers/", providerData,{
        //             headers: {
        //                 'Authorization': 'Basic ZDg3Zjk1ODlmNDdhNTk1YmFiOWUzNDg5ODAwMTg4NGQ6MGU3MzUzZGQ0ZmUyNGFlN2EzNDgzZmZkNWIzNTAyMzc='
        //             },
        //         }).then((res) => {
        //             // console.log(res);
        //             if(res.data.code == '0086-000') {
        //                 shared.toastrSuccess('Provider Successfully Added');
        //                 // this.$refs.providerTable.refresh();
        //                 this.refreshProviderTable();
        //                 this.$bvModal.hide('provider_modal');
        //                 this.$refs.loader.hide();
        //             }
        //         }).catch((error) => {
        //             console.log(error);
        //             this.$refs.loader.hide();
        //         });
        //     }
        // },

        // showProviderEdit(element) {
        //     // console.log(element);
        //     this.editProviderId = element.item.id;

        //     this.providerData.provider = {id:element.item.providerid, name: element.item.name},
        //     this.providerData.currency = {name : element.item.currency},
        //     this.providerData.assignedname = element.item.assignname,
        //     this.providerData.assignedcode = element.item.assigncode

        //     this.$bvModal.show('provider_modal');
        // },

        // updateProvider() {
        //     this.$refs.loader.show();
        //     let updateData = {
        //         pk: this.editProviderId,
        //         providerid: this.providerData.provider.id,
        //         bankid: this.bankID,
        //         assignedcode: this.providerData.assignedcode,
        //         assigned_name: this.providerData.assignedname,
        //         countryid: this.countryID,
        //         currency: this.providerData.currency.name
        //     };
        //     // console.log(updateData);
        //     this.$http.put(this.$standapp + "customer/bank/providers/", updateData,{
        //         headers: {
        //             'Authorization': 'Basic ZDg3Zjk1ODlmNDdhNTk1YmFiOWUzNDg5ODAwMTg4NGQ6MGU3MzUzZGQ0ZmUyNGFlN2EzNDgzZmZkNWIzNTAyMzc=' //`Basic ${token}`
        //         },
        //     }).then((res) => {
        //         // console.log(res);
        //         if(res.data.code == '0086-000') {
        //             shared.toastrSuccess('Provider Successfully updated');
        //             this.refreshProviderTable();
        //             this.$bvModal.hide('provider_modal');
        //             this.$refs.loader.hide();
        //         }
        //     }).catch((error) => {
        //         this.apiErrorCode = error.response.data.code;
        //         this.apiErrorMsg = error.response.data.message;
        //         this.$refs.loader.hide();
        //     });             
        // },

        // deleteProvider(providerID) {
        //     shared.deleteConfirm().then((result) => {
        //         if (result.isConfirmed) {
        //             this.$refs.loader.show();
        //             let deleteData = {
        //                 pk: providerID
        //             };
        //             this.$http.delete(this.$standapp + "customer/bank/providers/",{
        //                 headers: {
        //                     'Authorization': 'Basic ZDg3Zjk1ODlmNDdhNTk1YmFiOWUzNDg5ODAwMTg4NGQ6MGU3MzUzZGQ0ZmUyNGFlN2EzNDgzZmZkNWIzNTAyMzc=' //`Basic ${token}`
        //                 },
        //                 data : deleteData
        //             }).then((res) => {
        //                 if(res.data.code == '0086') {
        //                     shared.toastrSuccess('Provider Successfully deleted');
        //                     this.refreshProviderTable();
        //                     this.$refs.loader.hide();
        //                 } else {
        //                     this.refreshProviderTable();
        //                     this.$refs.loader.hide();
        //                     shared.toastrError(res.data.message);
        //                 }
        //             }).catch((error) => {
        //                 this.apiErrorCode = error.response.data.code;
        //                 this.apiErrorMessage = error.response.data.message;
        //             });
        //         }
        //     })
        // }, 
    },data(){
        return{
            isVisibleFilter: false,

            country: null,
            countrys_options: [],
            //items
            items:[],
           
            fields: [
                { key: 'uuid', label: 'UUID',sortable: true,thClass:'th_sortfix' },
                { key: 'name', label: 'Bank Name',sortable: true,thClass:'th_sortfix' },
                { key: 'code', label: 'Payangel Bank Code',sortable: true,thClass:'th_sortfix' },
                { key: 'countryname', label: 'Country',sortable: true,thClass:'th_sortfix' },
                { key: 'provider', label: 'Provider',sortable: true,thClass:'th_sortfix' },
                // { key: 'status', label: 'Status' },
                { key: 'actions', label: 'Actions' },
                { key: 'show_details', label: 'Show Provider', thStyle:'width:40px'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true },
            ],

            totalRows: 1,
            currentPage: 1,
            currentPageJump: 1,
            perPage: 50,
            pageOptions: [50,100, 150, 200, { value: 1000, text: 1000 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            searchText:'',
            modalTitle: '',
            bankId: '',
            bankData:{
                country:null,
                name:'',
                bankcode:''
            },

            apiErrorCode:'',
            apiErrorMsg: '',

            providerItems:[],
           
            //All Acounts list table header fields
            providerFields: [
                { key: 'name', label: 'Provider Name',sortable: true,thClass:'th_sortfix'},
                { key: 'assignname', label: 'Provider Assigned Name',sortable: true,thClass:'th_sortfix'},
                { key: 'assigncode', label: 'Provider Assigned Code',sortable: true,thClass:'th_sortfix'},
                { key: 'actions', label: 'Actions' },
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            bankID:'',
            countryID:'',
            bankcode:'',
            currencyItems:[],
            masterProviderItems:[],
            editProviderId:'',
            providerData:{
                provider:null,
                currency:'',
                assignedname:'',
                assignedcode:''
            }
        }
    },mounted() {
        this.allCountry();
    }
}
</script>
